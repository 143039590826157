import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import './assets/style.css';
import App from './App.vue';
import routes from './routes';
import AnimationFramePolyfill from './utils/polyfills';

new AnimationFramePolyfill();

const router = createRouter({
  linkActiveClass: 'selected',
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  routes,
});

// init
const app = createApp(App);

app.use(router);

app.mount('body');

