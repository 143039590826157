const Work = () => import('domains/work/Work');
const WorkLayout = () => import('domains/work/WorkLayout');
const About = () => import('domains/about/About');
const Contact = () => import('domains/contact/Contact');
const AdminDashboard = () => import('domains/work/AdminDashboard');
const DataAnalytics = () => import('domains/work/DataAnalytics');
const GiftTags = () => import('domains/work/GiftTags');
const MailroomKiosk = () => import('domains/work/MailroomKiosk');
const NasaRedesign = () => import('domains/work/NasaRedesign');
const PackageTracking = () => import('domains/work/PackageTracking');
const Pawsome = () => import('domains/work/Pawsome');
const SFS = () => import('domains/work/SFS');
const Salty = () => import('domains/work/Salty');
const fourOhfour = () => import('components/404');

export default [{
  path: '/',
  name: 'home',
  component: Work,
}, {
  path: '/work',
  component: WorkLayout,
  name: 'work',
  children: [{
    path: 'nasa-redesign-concept',
    name: 'nasa',
    component: NasaRedesign,
  }, {
    path: 'package-tracking',
    name: 'packageTracking',
    component: PackageTracking,
  }, {
    path: 'data-analytics',
    name: 'dataAnalytics',
    component: DataAnalytics,
  }, {
    path: 'admin-dashboard',
    name: 'adminDashboard',
    component: AdminDashboard,
  }, {
    path: 'mailroom-kiosk',
    name: 'mailroomKiosk',
    component: MailroomKiosk,
  }, {
    path: 'gift-tags',
    name: 'giftTags',
    component: GiftTags,
  }, {
    path: 'pawsome',
    name: 'pawsome',
    component: Pawsome,
  }, {
    path: 'sfs',
    name: 'sfs',
    component: SFS,
  }, {
    path: 'salty',
    name: 'salty',
    component: Salty,
  }]
}, {
  path: '/about',
  component: About,
  name: 'about'
}, {
  path: '/contact',
  component: Contact,
  name: 'contact'
}, {
  path: '/:pathMatch(.*)*',
  component: fourOhfour
}];
