export default class AnimationFramePolyfill {
  constructor() {
    this.lastTime = 0;
    
    this.vendors = ['ms', 'moz', 'webkit', 'o'];

    this._init();
  }

  _init() {
    for (let x = 0; x < this.vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame = window[this.vendors[x] + 'RequestAnimationFrame'];

      window.cancelAnimationFrame = window[this.vendors[x] + 'CancelAnimationFrame'] || window[this.vendors[x] + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
      window.requestAnimationFrame = function(callback, element) {
        let currTime = new Date().getTime();

        let timeToCall = Math.max(0, 16 - (currTime - this.lastTime));

        let id = window.setTimeout(function() {
            callback(currTime + timeToCall);
          },
          timeToCall);

        this.lastTime = currTime + timeToCall;

        return id;
      };

    if (!window.cancelAnimationFrame)
      window.cancelAnimationFrame = function(id) {
        clearTimeout(id);
      };
  }
}